import { PricingProductEnum } from "@finq/pricing/types"

type ScrollToPaymentProps = {
  productId: PricingProductEnum
  elementId?: `#${string}`
  offsetY?: number
}

export function useScrollToPayment() {
  const { scrollTo } = useScrollUtils()
  const { navigateToSubscribe } = useNavigateToSubscribe()

  return {
    /**
     * Scrolls to the payment section of the page OR navigate to the pricing page if the section is not found.
     */
    scrollToPayment: (props: ScrollToPaymentProps) => {
      const { productId, elementId, offsetY = 0 } = props

      const scrollToElement = document.querySelector(elementId!) as HTMLElement

      if (scrollToElement) {
        scrollTo(elementId, { offsetY })
      } else {
        navigateToSubscribe(productId)
      }
    },
  }
}
